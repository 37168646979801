
import { defineComponent, onMounted, onUpdated } from "vue";
// import { DrawerComponent } from "@/assets/ts/components/_DrawerComponent";
// import { ToggleComponent } from "@/assets/ts/components/_ToggleComponent";
// import KTAsidePrimary from "@/layout/aside/AsidePrimary.vue";
// import KTAsideSecondary from "@/layout/aside/AsideSecondary.vue";

export default defineComponent({
  name: "KTAside",
  components: {
    // KTAsidePrimary,
    // KTAsideSecondary,
  },
  props: {
    lightLogo: String,
    darkLogo: String,
  },
  setup() {
    onMounted(() => {
      // DrawerComponent.reinitialization();
      // ToggleComponent.reinitialization();
    });

    onUpdated(() => {
      // ToggleComponent.bootstrap();
    });
  },
});
