
import { defineComponent, onMounted, ref } from "vue";
import bitacora from "@/services/bitacora.service";

export default defineComponent({
  name: "topbar",
  components: {},
  setup() {
    const bitacoras = ref([]);
    const traerBitacoras = () => {
      bitacora.traerListadoBitacoraTres().then((res) => {
        console.log("respuesta bitacoras", res.data.data);
        bitacoras.value = res.data.data;
      });
    };
    onMounted(() => {
      traerBitacoras();
    });
    return { bitacoras };
  },
});
